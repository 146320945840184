import React from 'react';
import styled from 'styled-components';

const TeslaFeature = (): JSX.Element => {
  return (
    <>
      <StudyContainer paddingBottom="10px" direction="row;" >
        <StudyName color="#D6D6D6" font="16px;" width="600px;">Graduate Thesis</StudyName>
        <StudyDetail color="#D6D6D6" width="600px;">Goal</StudyDetail>
      </StudyContainer>
      <StudyContainer paddingTop="0" direction="row;">
        <StudyName bold="bold" font="18px;" width="600px;">
          <Span><Span color="#2F82FF">Tesla</Span> <Span>Take Over Request</Span></Span>
          <Span>
            Experience Case Study
          </Span>
        </StudyName>
        <StudyDetail width="600px;">
          Design whether the setting of driving proficiency affects the
          situation awareness of non-driving tasks in the context of a planned
          control transition aimed at a safe driving experience.
        </StudyDetail>
      </StudyContainer>
      <Image src="/images/tesla/teslabg.png" />
      <StudyContainer>
        <StudyName>Background</StudyName>
        <Article >
          <StudyDetail width='50%' >
            Level 3 Autonomous Driving infers conditional automation. This means
            the vehicle can take over all driving functions under certain
            circumstances. At this level, the driver can fully disengage until the
            vehicle tells you otherwise. Going from level 2 to level 3 requires
            substantial increases in functional safety levels and system
            redundancies. In certain circumstances, the vehicle will need to ask
            the driver to re-engage. Since the driver cannot be assumed to take
            control instantaneously, the system will need to ensure safety for a
            period of time when the driver is still not engaged. This Case Study
            aims to simulate and find the optimum methods that suit under level 3
            autonomous driving based on Tesla interface.
          </StudyDetail>
          <Img src='/images/tesla/tesla.png' alt="tesla" />
        </Article>
      </StudyContainer>
      <StudyContainer>
        <StudyName>From Secondary Research</StudyName>
        <Article>
          <StudyDetail >
            At the seminar on research results of self-driving cars R&D and testbeds, the Electronics and Tellecommunications Research Institute (ETRI) considers the time it takes for a person to recognize and drive manually in 4 seconds in international standards. The results of the actual ETRI simulation of 91 people directly and it took an average of 14.25 seconds for the driver to fully return to his usual driving skills. This means that for a safe self-driving experience, at least 19 seconds of time is needed from requesting control to stabilizing phase.
            <br />
            <br />
            However, these figures represent average values that do not take into account driver variables, which require more detailed user modeling when designing the user experience of the interface.
          </StudyDetail>
        </Article>
      </StudyContainer>
      <Img src='/images/tesla/Research.png' alt="research" />

      <StudyContainer>
        <StudyName>Hypothesis</StudyName>
      </StudyContainer>
      <Img src="/images/tesla/hypothesis.png" width="575px;" />
      <StudyContainer>
        <Article content="center">
          <StudyDetail width="678px;">
            Based on the driver's stabilization phase standard of 19 seconds from the time of notification of take over, it was intended to measure the situation awareness of the control level according to the driving proficiency by considering the driver's variables.
          </StudyDetail>
        </Article>
      </StudyContainer>

      <StudyContainer direction="column">
        <StudyName>Design Scope with Scenario</StudyName>
        <ImageScenario src="/images/tesla/User Scenario.png" />
      </StudyContainer>

      <StudyContainer>
        <StudyName>Visualize</StudyName>
        <Article content="flex-end">
          <StudyDetail width='50%' >
            The infotainment UI was implemented as much as possible through Figma by referring to the driver's manual on Tesla's official website. In particular,
            I designed the driver proficiency screen to be mandatory before using NOA according to lv3 autonomous driving, and designed a safe driving experience by notifying algorithms about take over request notification times.
          </StudyDetail>
        </Article>
      </StudyContainer>
      <Section1>
        <Section1Image src="/images/tesla/section1/Settings.png" />
        <Section1Image src="/images/tesla/section1/Driving Proficiency.png" />
      </Section1>
      <Section1>
        <Section1Image src="/images/tesla/section1/NOA_Settings.png" />
        <Section1Image src="/images/tesla/section1/NOA_Announcement.png" />
      </Section1>
      <Label>Setting Phase</Label>
      <Label>During the setting phase, three levels of driving proficiency were essentially set for drivers using NOA. In particular, it was possible to emphasize the importance of setting the driving proficiency by notifying that the operation proficiency setting determines the take over request notification times.</Label>
      <Section1>
        <Section2Image src="/images/tesla/section2/Landing.png"></Section2Image>
        <Section2Image src="/images/tesla/section2/Autopilot.png"></Section2Image>
        <Section2Image src="/images/tesla/section2/NOA.png"></Section2Image>
      </Section1>
      <Label>NOA Driving Phase</Label>
      <Section1>
        <Section1Image src="/images/tesla/section3/TOR1.png" />
        <Section1Image src="/images/tesla/section3/TOR2.png" />
      </Section1>
      <Section1>
        <Section1Image src="/images/tesla/section3/TOR3.png" />
        <Section1Image src="/images/tesla/section3/Arrival.png" />
      </Section1>
      <Label>TOR Phase</Label>
      <Label>Since the goal was to design the experience of safe take over notifications considering driver variables during non-driving tasks, the key was to notify take over control notification on the infotainment screen in the limited area. In consideration of intuition, color-coded notification banners were designed, and emergency rescue screens were designed in consideration of driver's risk conditions such as driver's cardiac arrest when control is not changed.</Label>

      <StudyContainer>
        <StudyName>WoZ Usability Testing</StudyName>
        <Article >
          <StudyDetail width='50%' >
            To verify the hypothesis, I planned a usability test using a real vehicle, but due to safety issues and legal limitations, Tesla's infotainment could not be completely replaced. Alternatively, the test was conducted using WoZ methodology, which recruited 10 experimenters with level 2 autonomous driving experience. Since Navigation on Autopilot is only active on expressways and motorways, it has been driven from Mannam-eui Gwangjang to Pangyo IC. In addition, 2 back games were assigned to the driver to verify control conversion during non-driving tasks.
          </StudyDetail>
          <Img src='/images/tesla/WoZ.png' alt="WoZ" />
        </Article>
      </StudyContainer>
      <Section1>
        <Section2Image src="/images/tesla/WoZ/WoZ1.png" width="420px"></Section2Image>
        <Section2Image src="/images/tesla/WoZ/WoZ2.png" width="240px"></Section2Image>
        <Section2Image src="/images/tesla/WoZ/WoZ3.png" width="420px"></Section2Image>
      </Section1>

      <StudyContainer>
        <StudyName>Situation Awareness Measurement</StudyName>
        <Article >
          <StudyDetail width='50%' >
            The situation awareness rating technique (SART) (Taylor 1990) is a simplistic post-trial subjective rating technique that was originally developed for the assessment of pilot SA. SART uses the following ten dimensions to measure operator SA: Familiarity of the situation, focussing of attention, information quantity, information quality, instability of the situation, concentration of attention, complexity of the situation, variability of the situation, arousal, and spare mental capacity.
            SART is administered post trial and involves participants subjectively rating each dimension on a seven point rating scale (1 = Low, 7 = High) based on their performance of the task under analysis. The ratings are then combined in order to calculate a measure of participant SA.
            Through this method, qualitative assessments were conducted in accordance with the SART measurement to obtain situation awareness values.
          </StudyDetail>
          <Img src='/images/tesla/Situation/Situation1.png' alt="Situation1" />
        </Article>
      </StudyContainer>
      <Img width="1130px" src='/images/tesla/Situation/Situation2.png' alt="Situation2" />

      <StudyContainer paddingBottom="300px">
        <StudyName>Reflection</StudyName>
        <Article >
          <StudyDetail width='50%' >
            This case study assumed lv3 autonomous driving within the Tesla interface and tried to measure the driver's situation awareness in the non driving task by considering driver variables. However, while the very limited experimental conditions do not account for external environmental variables such as traffic conditions and weather conditions on the roads, and the hypothesis verified by small samples may be statistically significant.
          </StudyDetail>
        </Article>
      </StudyContainer>

    </>
  );
};

const Section2Image = styled.img<{ width?: string }>`
width: ${(props) => (props.width ? props.width : '365px')};
`;

const StudyContainer = styled.div<{ direction?: string; paddingTop?: string; paddingBottom?: string; }>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  width: 100%;
  max-width: 1130px;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '50px')};
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : '50px')};
  gap: 20px;
`;


const Article = styled.article<{ content?: string; }>`
  display: flex;
  justify-content: ${(props) => (props.content ? props.content : 'space-between')};
  gap: 15px;
`;

const StudyDetail = styled.section<{ width?: string, color?: string }>`
font-size: 16px;
width: ${props => props.width ? props.width : '100%'};
color: ${(props) => props.color || 'white'};
line-height: 20px;
`;

const StudyName = styled.article<{ font?: string; bold?: string; color?: string; width?: string; }>`
  flex-direction: column;
  font-size:  ${(props) => (props.font ? props.font : '20px')};
  display: flex;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  color: ${(props) => props.color || 'white'};
  line-height: 20px;
  width: ${(props) => (props.width ? props.width : 'auto')};
`;

const Img = styled.img<{ width?: string; }>`
width: ${(props) => (props.width ? props.width : '45%')};
height: auto;
`;

const Span = styled.div<{ color?: string; }>`
color: ${(props) => props.color ? props.color : 'white'};
display: inline;
`;


const Label = styled.div`
font-size: 16px;
padding-bottom: 40px;
max-width: 500px;
`;

const Section1 = styled.div`
max-width: 1130px;
display: flex;
height: auto;
gap: 20px;
padding-top: 20px;
padding-bottom: 20px;
`;

const Section1Image = styled.img`
width: 555px;
`;

const ImageScenario = styled.img`
max - width: 1130px;
`;

const Image = styled.img`
width: 1130px;
padding - top: 30px;
padding - bottom: 30px;
`;


const Redirect = styled.a`
font - weight: bold;
font - size: 30px;
margin - top: 50px;
margin - bottom: 50px;
cursor: pointer;
line - height: 40px;
  &: hover {
  background: linear - gradient(to right, #ac7fd0, #99cfff);
  -webkit - background - clip: text;
  -webkit - text - fill - color: transparent;
}
`;

export default TeslaFeature;