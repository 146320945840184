import React from 'react';
import styled from 'styled-components';

const RMIT = (): JSX.Element => {
    return (
        <>
            <RMITContainer>
                <Container>
                    <Intro>
                        <StudyName color="#D6D6D6" size="16px" >Extra-Crurricular Activities</StudyName>
                        <StudyDetail size="18px" >RMIT UI Design Challenge</StudyDetail>
                        <StudyName color="#D6D6D6" >Goal</StudyName>
                        <StudyDetail>RMIT Digital Media UX Group started UI design challenge to inspire other design students who are engaged in different domain. During the trial period, multiple UI set was produced per week. Throughout the process of discussing usability from an aesthetic point of view, we identified advanced designs for each meeting.
                            This activity was built to provide a fun engaging way to improve our skills as a designer, get feedback from others.
                            Plus, below UI Design sets that our team worked on together are blurred because it is attributed to RMIT UX—HCI Group.</StudyDetail>
                        <StudyName color="#D6D6D6" >Duration</StudyName>
                        <StudyDetail>12 weeks </StudyDetail>
                    </Intro>
                    <IMG src="/images/rmit/RMIT.png" alt="rmit" width="350px" />
                </Container>
                <IMG src="/images/rmit/rmit1.png" alt="challenge" width="710px" paddingTop="80px" paddingBottom="80px" />
            </RMITContainer>
        </>
    );
};

const Intro = styled.article<{ direction?: string; width?: string; paddingTop?: string; }>`
  display: flex;
  flex-direction: ${(props) => props.direction ? props.direction : 'column'};
  max-width: ${(props) => props.width ? props.width : '50%'};
  justify-content: center;
  padding-top: ${(props) => props.paddingTop ? props.paddingTop : '0'};
`;

const StudyName = styled.div<{ color?: string; size?: string; }>`
  color: ${(props) => props.color ? props.color : 'white'};
  font-size: ${(props) => props.size ? props.size : "16px"};
  padding-bottom: 17px;
`;

const StudyDetail = styled.div<{ size?: string; paddingBottom?: string; width?: string; }>`
  font-size: 16px;
  padding-bottom: ${(props) => props.paddingBottom ? props.paddingBottom : '30px'};
  width: ${(props) => props.width ? props.width : 'auto'};
`;


const IMG = styled.img<{ width?: string; height?: string; paddingBottom?: string; paddingTop?: string; }>`
  width: ${(props) => props.width ? props.width : '1130px'};
  height: ${(props) => props.height ? props.height : 'auto'};
  padding-top : ${(props) => props.paddingTop ? props.paddingTop : '0'};
  padding-bottom: ${(props) => props.paddingBottom ? props.paddingBottom : '0'};
`;

const Container = styled.div<{ paddintBottom?: string }>`
  align-items: center;
  padding-top: 60px;
  width: 1130px;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${(props) => props.paddintBottom ? props.paddintBottom : '0'};
`;


const RMITContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export default RMIT;

