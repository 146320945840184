import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const Resume = (): JSX.Element => {
    return (
        <>
            <Container>
                <Item>
                    <Text>Currently seeking for a full-time job..</Text>
                    <ResumeImg src={'/images/resume/resume.png'} alt="" />
                </Item>
            </Container>
            <FooterContainer>
                <FooterList>
                    <FooterItem
                        href="https://www.linkedin.com/in/kyung-seok-oh-022510143"
                        target="_blank"
                    >
                        LinkedIn
                    </FooterItem>
                    |
                    <FooterItem
                        href="https://www.instagram.com/kaisennabe/"
                        target="_blank"
                    >
                        Instagram
                    </FooterItem>
                </FooterList>
            </FooterContainer>
        </>
    );
};

const FooterList = styled.li`
  display: flex;
  gap: 30px;
  justify-content: center;
  color: #7D7D7D;
`;

const FooterItem = styled.a`
  font-size: 1rem;
  gap: 20px;
  &: hover {
    background: linear-gradient(to right, #ac7fd0, #99cfff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const FooterContainer = styled.footer`
  justify-content: center;
  padding-top: 70px;
//   padding-bottom: 50px;
  height: 100px;
  bottom: 0px;
  width: 100%;
`;

const Text = styled.div`
font-weight: bold;
font-size: 16px;
color: #C2C2C2;
`;

const Container = styled.div`
padding-top: 100px;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding-bottom: 70px;
`;

const ResumeImg = styled.img`
width: 700px;
height: auto;
`;

const Item = styled.div`
  width: 1130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 35px;
`;

export default Resume;
