import React from 'react';
import styled from 'styled-components';
import Projects from './Projects';
import { Link } from 'react-router-dom';

const projects = [
  {
    intro: '#HMI Research #WoZ',
    projectName: 'Tesla Take Over Request Experience Case Study',
    projectSub: 'based on Lv3 Autonomous Driving',
    img: '/images/home/TeslaThumnail.png',
    article:
      'Interface Design Proposal With the goal of designing a safe driving experience, I suggest the driver variables according to the SAE lv3 automation guide and measure driving proficiency base situation awareness in planned control transitions during non-driving tasks.',
    imgBtn: '/images/home/viewcasestudy_button.png',
    path: '/tesla',
  },
  {
    intro: '#UX Research #AR #Camera',
    projectName: 'Yarra Trash Monsters',
    projectSub: ' Community-Driven AR Camera Service Design',
    img: '/images/home/Yarra_thumbnail.png',
    article:
      'Interface Design Proposal With the goal of designing a safe driving experience, I suggest the driver variables according to the SAE lv3 automation guide and measure driving proficiency base situation awareness in planned control transitions during non-driving tasks.',
    imgBtn: '/images/home/viewproject_button.png',
    path: '/yarratrashmonsters',
  },
  {
    intro: '#VUX #FGI #GoogleAction #Dialogflow',
    projectName: 'Louis',
    projectSub: 'Voice Enabled Chatbot for Visual Impaired',
    img: '/images/home/TeslaThumnail.png',
    article:
      'Interface Design Proposal With the goal of designing a safe driving experience, I suggest the driver variables according to the SAE lv3 automation guide and measure driving proficiency base situation awareness in planned control transitions during non-driving tasks.',
    imgBtn: '/images/home/viewproject_button.png',
    path: '/louis',
  },
];

const SectionFeature = (): JSX.Element => {
  return (
    <>
      <FeatureContainer>
        <Features>
          <Text> ⎯ Featured</Text>
          <Images>
            <FeatureImgContainer to={'/tesla'}>
              <FeatureImg className="image" src='/images/home/teslabg.png' alt="" />
              <Overlay className="text">
                <TextHover>Tesla Take Over Request Experience Case Study <br /> based on Lv3 Autonomous Driving</TextHover>
                <br />
                <TextHover>UX / HMI</TextHover>
              </Overlay>
            </FeatureImgContainer>
            <FeatureImgContainer to={'/Melbournetrashmonsters'}>
              <FeatureImg className="image" src='/images/home/Group 548.png' alt="" />
              <Overlay className="text">
                <TextHover>Melbourne Trash Monsters<br />
                  Social AR Camera Service</TextHover>
                <br />
                <TextHover>UX / UI / AR</TextHover>
              </Overlay>
            </FeatureImgContainer>

          </Images>
          <Images>
            <Exception>
              <FeatureImg className="image" src='/images/home/Group 550.png' alt="" />
            </Exception>
            <FeatureImgContainer to={'/rmit'}>
              <FeatureImg className="image" src='/images/home/Group 537.png' alt="" />
              <Overlay className="text">
                <TextHover>RMIT UI Design Challenge</TextHover>
              </Overlay>
            </FeatureImgContainer>
          </Images>
        </Features>
      </FeatureContainer>
    </>
  );
};


const Images = styled.div`
display: flex;
grid-gap: 29px;
`;
const Exception = styled.div`
width: 50%;
height: auto;
`;

const Overlay = styled.div`
transition: .5s ease;
opacity: 0;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
text-align: center;
`;

const TextHover = styled.div`
color: white;
font-size: 16px;
width: 400px;
`;

const FeatureImgContainer = styled(Link)`
position: relative;
width: 50%;
cursor: pointer;

&:hover .image {
  opacity: 0.3;
}

&:hover .text {
  opacity: 1;
}
`;

const FeatureImg = styled.img`
width: 546px;
opacity: 1;
display: block;
width: 100%;
height: auto;
transition: .5s ease;
backface-visibility: hidden;
`;

const Features = styled.div`
  width: 1130px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Text = styled.div`
  font-size: 26px;
  font-weight: bold;
`;

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  aligm-items: center;
  width: 100%;
  padding-top: 100px;
`;
export default SectionFeature;
