import React from 'react';
import styled from 'styled-components';

const SectionEdu = (): JSX.Element => {
  return (
    <>
      <Container>
        <EduContainer>
          <EduIntro>⎯ Other Visual Work</EduIntro>
          <EduImgContainer href="https://vimeo.com/529882425" target="_blank" >
            <EduImg src={'/images/home/pocket.png'} className="image" />
            <Overlay className="text">
              <TextHover>The Ethical Dilemma of Self-Driving Cars
                Infographic Video</TextHover>
              <br /><TextHover> Illustrator / AfterEffects</TextHover>
            </Overlay>
          </EduImgContainer>
        </EduContainer>
      </Container>
    </>
  );
};


const EduImgContainer = styled.a`

width: 1130px;
position: relative;
cursor: pointer;

&:hover .image {
  opacity: 0.3;
}

&:hover .text {
  opacity: 1;
}
`;

const Overlay = styled.div`
transition: .5s ease;
opacity: 0;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
text-align: center;
`;

const TextHover = styled.div`
color: white;
font-size: 16px;
width: 400px;
`;

const EduImg = styled.img`

opacity: 1;
display: block;
width: 100%;
height: auto;
transition: .5s ease;
backface-visibility: hidden;
`;

const Container = styled.div`
  padding-top: 80px;
  padding-bottom: 45px;
`;

const EduContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 20px;
`;

const EduIntro = styled.div`
  font-size: 26px;
  width: 1130px;
  font-weight: bold;
`;


export default SectionEdu;
