import React from 'react';
import styled from 'styled-components';

const SectionIntro = (): JSX.Element => {
  return (
    <>
      <Intro>
        <IntroContainer>
          <Item>

            <IntroItem1>Kyung Seok Oh</IntroItem1>
            <IMG src="/images/home/dizzy.png" />
          </Item>
          <IntroItem2>
            a product designer, creating salient experiences based on
            <br /> user experience principles and HCI Research.
          </IntroItem2>
        </IntroContainer>
      </Intro>
    </>
  );
};
const Item = styled.div`
  display:flex;
  gap: 10px;
  align-items: center;
`;

const IMG = styled.img`
  width: 40px;
  height: 40px;
`;

const Intro = styled.div`
  justify-content: center;
  display: flex;
`;
const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 50px;
  width: 1130px;

  //   @media only screen and (max-width: 744px) {
  //     width: 100%;
  //   }
`;

const IntroItem1 = styled.div`
  width: 328px;
  line-height: 50px;
  font-size: 44px;
  font-weight: bold;
  background: linear-gradient(to right, #ac7fd0, #99cfff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const IntroItem2 = styled.div`
  font-size: 22px;
`;

export default SectionIntro;
