import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';


const Nav = (): JSX.Element => {
    const history = useHistory();
    const redirect = () => {
        history.push('/');
    };

    return (
        <>
            <HeaderContainer>
                <Item>
                    <MainLogo src={'/images/home/MainLogo.png'} onClick={redirect} ></MainLogo>
                    <Resume to={'/resume'}>resume</Resume>
                </Item>
            </HeaderContainer>
        </>
    );
};


const HeaderContainer = styled.header`
  width: 100%;
  height: 100px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &: hover {
      background-color: #2D2D2D;
  }
`;

const MainLogo = styled.img`
  width: 2.5rem;
  
  cursor: pointer;
`;

const Item = styled.div`
  width: 1130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Resume = styled(Link)`
  font-size: 1.1rem;
  cursor: pointer;
  &: hover {
    transition: all 0.9s;
    transform: scale(1.02);
    background: linear-gradient(to right, #ac7fd0, #99cfff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &: active {
    transform: scale(1.05);
  }
`;


export default Nav;