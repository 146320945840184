import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import HomePage from './pages/HomePage';
import TeslaPage from './pages/TeslaPage';
import MelbournetrashmonstersPage from './pages/MelbournetrashmonstersPage';
import LouisPage from './pages/LouisPage';
import RMITPage from './pages/RMITPage';
import ResumePage from './pages/ResumePage';
import Footer from './components/home/Footer';
import Nav from './container/Nav';

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Nav />
      <Switch>
        <Route path="/" component={HomePage} exact={true} />
        <Route path="/tesla" component={TeslaPage} />
        <Route path="/melbournetrashmonsters" component={MelbournetrashmonstersPage} />
        <Route path="/louis" component={LouisPage} />
        <Route path="/rmit" component={RMITPage} />
        <Route path="/resume" component={ResumePage} />
        <Redirect from="*" to="/" />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
