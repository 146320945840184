import React from 'react';
import styled from 'styled-components';
import TeslaFeature from '../components/TeslaFeature';

const Tesla = (): JSX.Element => {
  return (
    <>
      <TeslaContainer>
        <TeslaFeature />
      </TeslaContainer>
    </>
  );
};

const TeslaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export default Tesla;
