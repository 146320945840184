import React from 'react';
import styled from 'styled-components';

const Melbournetrashmonsters = (): JSX.Element => {
  return (
    <>
      <MelbourneTrashContainer>
        <MTMContainer>
          <Intro>
            <StudyName color="#D6D6D6" size="16px" >Federation Squre Project</StudyName>
            <StudyDetail size="18px" >Melbourne Trash Monsters <br /> Social AR Camera Service</StudyDetail>
            <StudyName color="#D6D6D6" >Goal</StudyName>
            <StudyDetail>MTM is a social AR camera service for Melbourne juvenile. Instead of simply using content to raise environmental awareness, we designed a user experience that can add entertainment characteristics and direct action using augmented reality, corresponding to Generation Z’s behavior patterns.</StudyDetail>
            <StudyName color="#D6D6D6" >Tools</StudyName>
            <StudyDetail>FGI, ESM for Research <br />
              Figma, Spark AR and AfterEffects for Prototype</StudyDetail>
            <StudyName color="#D6D6D6" >Duration</StudyName>
            <StudyDetail>12 weeks </StudyDetail>
          </Intro>
          <IMG src="/images/mtm/intro.png" alt="intro" width="350px" />
        </MTMContainer>
        <MTMContainer>
          <Intro>
            <StudyName size="24px" >Background</StudyName>
            <StudyDetail paddingBottom="120px">Based on a research that the amount of trash collected at the actual site is smaller than the number of public waste bins in Melbourne, mobile services were planned and implemented as part of the Federation Square project. To achieve a particular behavior of "picking up trash", a special trigger was needed and focused on how to melt it well into the mobile environment. In particular, the service was designed for teenagers who are the main targets of the service to identify their contextual environment, such as their radius of action, and to induce specific behaviors such as 'picking up trash'. In this process, potential problems that hinder the goal of the service were identified through user research and sought to provide a more meaningful experience.</StudyDetail>
            <StudyName size="24px" >Research</StudyName>
            <StudyDetail>I seek to find the exact needs and inconveniences of generation Z by borrowing the user behavior pattern mapping scheme of the pxd UX agency in South Korea. <br /><br />
              I recruited 15 teenagers to conduct an FGI on behavioral variables to identify similarities between user behavior and motivation, and group them together to discover key patterns to clarify the target's focus. When you connect a cluster of people in a longitudinal direction, it looks like this: </StudyDetail>
          </Intro>
          <IMG src="/images/mtm/background.png" alt="background" width="335px" height="290px" />
        </MTMContainer>
        <IMG src="/images/mtm/research1.png" alt="research1" paddingBottom="30px" />
        <IMG src="/images/mtm/research2.png" alt="research2" />
        <MTMContainer>
          <Intro>
            <StudyName size="24px" >Experience Sampling Method (ESM)</StudyName>
            <StudyDetail width="900px" >Physical ESM was conducted as a second research methodology because it provides ecological access to experiences lying in real-time environments and has the advantage of being able to track the persistent interactions between individuals and environments on thoughts, feelings, symptoms.
              <br /><br />
              The merits of ESM are behavior analogy based on situation, ecological validity, possibility of inquiry into with in-person change, reduction of memory bias, and the potentiality of blending multiple methods.
              <br /><br />
              However, there was a real problem in daily life when sampling groups of users who pick up trash. To overcome this, 15 students who conducted the FGI were asked to perform environmental beautification volunteer work at the industrial complex in Melbourne and to report their actions, interactions, thoughts, and feelings at random times or at regular intervals.</StudyDetail>
          </Intro>
        </MTMContainer>
        <IMG src="/images/mtm/experience.png" alt="experience" />
        <MTMContainer>
          <Intro>
            <StudyName size="24px" >Findings </StudyName>
            <StudyDetail  >According to the FGI and ESM qualitatitive data collected from participants, the summary of the major themes that were grouped together were: </StudyDetail>
          </Intro>
        </MTMContainer>
        <br />
        <IMG src="/images/mtm/findings.png" width="1000px" alt="findings" />
        <MTMContainer>
          <Intro>
            <StudyName size="24px" >Ideation </StudyName>
            <StudyDetail >I created some initial sketches of low-fidelity service concepts and brainstormed some ideas and features that would benefit users in their user goals. In particular, while maintaining the same design basis as other social camera services, the main concept of the service was to encourage certain behaviors, meaningful contents, and provide new experiences.</StudyDetail>
          </Intro>
        </MTMContainer>
        <IMG src="/images/mtm/ideation1.png" width="1000px" alt="ideation1" />
        <MTMContainer>
          <StudyDetail width="250px" >I created some initial sketches of low-fidelity service concepts and brainstormed some ideas and features that would benefit users in their user goals. In particular, while maintaining the same design basis as other social camera services, the main concept of the service was to encourage certain behaviors, meaningful contents, and provide new experiences.</StudyDetail>
          <IMG src="/images/mtm/ideation2.png" width="770px" alt="ideation2" />
        </MTMContainer>
        <MTMContainer>
          <Intro>
            <StudyName size="24px" >Service Flow </StudyName>
          </Intro>
        </MTMContainer>
        <IMG src="/images/mtm/service.png" width="750px" alt="service" paddingTop="40px" paddingBottom="105px" />
        <MTMContainer>
          <StudyName size="24px" >Design Solution</StudyName>
        </MTMContainer>
        <Solution>
          <IMGSolution1 src="/images/mtm/solution/solution1.png" alt="solution1" />
          <IMGSolution1 src="/images/mtm/solution/solution2.png" alt="solution2" />
          <IMGSolution1 src="/images/mtm/solution/solution3.png" alt="solution3" />
          <IMGSolution1 src="/images/mtm/solution/solution4.png" alt="solution4" />
          <IMGSolution1 src="/images/mtm/solution/solution5.png" alt="solution5" />
          <IMGSolution1 src="/images/mtm/solution/solution6.png" alt="solution6" />
        </Solution>
        <Intro paddingTop="60px">
          <StudyDetail width="630px" size="24px" >The most important consideration UI design was to induce certain behaviors of 'picking up and throwing away trash'. This makes the identity of the service more robust, while also reminding the activity of its purpose. In addition, in the process of registering garbage, it was allowed to register with its own trash assets through labeling process such as location information and weight.</StudyDetail>
        </Intro>
        <Solution>
          <IMGSolution2 src="/images/mtm/solution/solution7.png" alt="solution7" />
          <IMGSolution2 src="/images/mtm/solution/solution8.png" alt="solution8" />
          <IMGSolution2 src="/images/mtm/solution/solution9.png" alt="solution9" />
          <IMGSolution2 src="/images/mtm/solution/solution10.png" alt="solution10" />
        </Solution>
        <Solution>
          <Video loop={true}
            muted={true}
            // ref={ref}
            playsInline={true}
            autoPlay={true}
          > <source src="/images/mtm/solution/v1.mov" /></Video>
          <Video loop={true}
            muted={true}
            // ref={ref}
            playsInline={true}
            autoPlay={true}
          > <source src="/images/mtm/solution/v2.mov" /></Video>
        </Solution>
        <Solution>
          <IMGSolution2 src="/images/mtm/solution/solution11.png" alt="solution11" />
          <IMGSolution2 src="/images/mtm/solution/solution12.png" alt="solution12" />
          <IMGSolution2 src="/images/mtm/solution/solution13.png" alt="solution13" />
          <IMGSolution2 src="/images/mtm/solution/solution14.png" alt="solution14" />
        </Solution>
        <MTMContainer paddintBottom="100px">
          <Intro>
            <StudyName size="24px" >Reflection </StudyName>
            <StudyDetail> Throughout this project, It was a valuable experience to design with an organization that was concerned about and solved environmental problems around it. In particular, Researching a particular generation of users, It was a pleasant process to find the most appropriate research methodology and to form a rapport for teenage students. In addition, designing interfaces of commercial technologies such as AR and Metabus design were intensively studied during this project, so I could practice foundation for convergence platform design such as mixed reality for next project.
            </StudyDetail>
          </Intro>
        </MTMContainer>
      </MelbourneTrashContainer>
    </>
  );
};


const Video = styled.video`
width: 212px;
height: auto;
`;

const Solution = styled.div`
  display: flex;
  align-items: center;
  padding-top: 60px;
  width: 1130px;
  gap: 37px;
  justify-content: center;
`;

const IMGSolution1 = styled.img`
  width: 160px;
`;

const IMGSolution2 = styled.img`
  width: 160px;
`;

const IMG = styled.img<{ width?: string; height?: string; paddingBottom?: string; paddingTop?: string; }>`
  width: ${(props) => props.width ? props.width : '1130px'};
  height: ${(props) => props.height ? props.height : 'auto'};
  padding-top : ${(props) => props.paddingTop ? props.paddingTop : '0'};
  padding-bottom: ${(props) => props.paddingBottom ? props.paddingBottom : '0'};
`;

const Intro = styled.article<{ direction?: string; width?: string; paddingTop?: string; }>`
  display: flex;
  flex-direction: ${(props) => props.direction ? props.direction : 'column'};
  max-width: ${(props) => props.width ? props.width : '50%'};
  justify-content: center;
  padding-top: ${(props) => props.paddingTop ? props.paddingTop : '0'};
`;

const StudyName = styled.div<{ color?: string; size?: string; }>`
  color: ${(props) => props.color ? props.color : 'white'};
  font-size: ${(props) => props.size ? props.size : "16px"};
  padding-bottom: 17px;
`;

const StudyDetail = styled.div<{ size?: string; paddingBottom?: string; width?: string; }>`
  font-size: 16px;
  padding-bottom: ${(props) => props.paddingBottom ? props.paddingBottom : '30px'};
  width: ${(props) => props.width ? props.width : 'auto'};
`;

const MTMContainer = styled.div<{ paddintBottom?: string }>`
  align-items: center;
  padding-top: 60px;
  width: 1130px;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${(props) => props.paddintBottom ? props.paddintBottom : '0'};
`;

const MelbourneTrashContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
`;

export default Melbournetrashmonsters;