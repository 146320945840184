import React from 'react';
import styled from 'styled-components';

const Footer = (): JSX.Element => {
  return (
    <>
      <FooterContainer>
        <FooterList>
          Copyright 2021 @ Kyung Seok Oh
        </FooterList>
      </FooterContainer>
    </>
  );
};

const FooterList = styled.li`
  display: flex;
  gap: 30px;
  justify-content: center;
  color: #7D7D7D;
`;

const FooterContainer = styled.footer`
  justify-content: center;
  // padding-top: 50px;
  // padding-bottom: 50px;
  height: 100px;
  bottom: 0px;
  width: 100%;
`;

export default Footer;
